import {  useState, createContext } from 'react';

type GlobalContext = {
    tabValue: any;
    setTabValue: any;
    passwordToken:string;
    setPasswordToken:any;
    changeActiveStaus:boolean
    setChangeActiveStatus:any
    globaleventDetails:any
    setGlobaleventDetails:any
    loggedUserDetails:any,
    setLoggedUserDetails:any
    passodeExpireTime:any
    setPassodeExpireTime:any
    refreshNotificationList:boolean;
    serefreshNotificationList:any
    refreshTicketList:boolean
    , setrefreshTicketList:any
    filterData:any, 
    setFilterData:any,
    imgPath:any, 
    setImagePath:any,
    uploadedFilePath:any, 
    setUploadedFilePath:any,
    datafeedfileName:any,
    setdatafeedFileName:any,
    memberRefreshList:boolean, 
    setrefreshMembertList:any,
    displayPayNowButton:any, 
    setDisplayPayNowButton:any,
    page:number, 
    setPage:any,
    limit:number, 
    setLimit:any
    profilrPic:any, 
    setProfilePic:any
    imgProfilePath:any, 
    setProfileImagePath:any
    searchbyUserNm:string,
    setsearchbyUserNm:any,
    searchbyEventNm:string,
    setsearchbyEventNm:any,
    searchbyMemberEvantNm:string,
    setsearchbyMemberEvantNm:any
    searchbyMemberAllEvantNm:string,
    setsearchbyMemberAllEvantNm:any,
    ticketTodetails:any,
    setticketTodetails:any,
    searchbyTicketEvantNm:any,
    setsearchbyTicketEvantNm:any,
    searchmasterreport:string,
    setSearchmasterreport:any
    searchmealreport:string,
    setSearchmealreport:any,
    displayNotificationIcon:boolean, 
    setDisplayNotificationIcon:any,
    mobileOpen:boolean, 
    setMobileOpen:any,
    handleDrawerOpen:any,
    handleDrawerClose:any,
    imageLoader:boolean, 
    setImageLoader:any,
    loggedInUser:any, 
    setloggedInUser:any,
    afterUpdateMember:boolean, 
    setafterUpdateMember:any,
    searchbyNotificationNm:string,
    setsearchbyNotificationNm:any,
    globalSearchExpand: boolean,
    setGlobalSearchExpand:(value:boolean) => void,
    notificationCount:number, 
    setNotificationCount:(value:number) => void,
    searchPaymentMember:string,
    setSearchPaymentMember:(value:string) => void,
    updateFileArray:[],
     setUpdateFileArray:(value:[]) => void,
     storeAttachment:any,
     setStoreAttachment:any
     
};

interface ProfileInterface {
    imageUrl: string;
    baseUrl: string;
  }
  const initialProfile = {
    baseUrl: "",
    imageUrl: "",
  };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GlobalContext = createContext({} as GlobalContext);

export const GlobalContextProvider = (props: any) => {
    const { children } = props;
    const [tabValue, setTabValue] = useState(0);
    const [passwordToken, setPasswordToken] = useState('');
    const [changeActiveStaus, setChangeActiveStatus] = useState(false);
    //Ticket flow member book event store information
    const [globaleventDetails, setGlobaleventDetails] = useState([]);
    const [loggedUserDetails, setLoggedUserDetails] = useState([]);
    //forget Password Pass code expire time
    const [passodeExpireTime, setPassodeExpireTime] = useState();
    //after approve member refresh list
    const [refreshNotificationList, serefreshNotificationList] = useState(false);
    //Refresh List after Meal reference Changes
    const [refreshTicketList, setrefreshTicketList] = useState(false);
    //Event File Upload States
    const [filterData, setFilterData] = useState<any>([])
    const [imgPath, setImagePath] = useState<any>([])
    const [uploadedFilePath, setUploadedFilePath] = useState<any>()
    const [datafeedfileName, setdatafeedFileName] = useState()
    //After import refresh list
    const [memberRefreshList, setrefreshMembertList] = useState(false);
    const [displayPayNowButton, setDisplayPayNowButton] = useState(false);
    //Page And limit for Admin Event Table
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [profilrPic, setProfilePic] =   useState<ProfileInterface>(initialProfile);
    const [imgProfilePath, setProfileImagePath] = useState<any>(null)
    //Common search with different state
    const[searchbyEventNm,setsearchbyEventNm]=useState("")
    const[searchbyUserNm,setsearchbyUserNm]=useState("")
    const[searchbyMemberEvantNm,setsearchbyMemberEvantNm]=useState("")
    const[searchbyMemberAllEvantNm,setsearchbyMemberAllEvantNm]=useState("")
    const[searchbyTicketEvantNm,setsearchbyTicketEvantNm]=useState("")
    //store Eventdetails
    const[ticketTodetails,setticketTodetails]=useState<any>("")
    //master report search
    const[searchmasterreport,setSearchmasterreport]=useState<any>("")
    const[searchmealreport,setSearchmealreport]=useState<any>("")
    //display Notification Icon
    const [displayNotificationIcon, setDisplayNotificationIcon] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerOpen = () => {
      setMobileOpen(true);
    };
    const handleDrawerClose = () => {
        setMobileOpen(false);
      };
    const [imageLoader, setImageLoader] = useState(false);
    //Logged in member information store in state
    const [loggedInUser, setloggedInUser] = useState()
    const [afterUpdateMember, setafterUpdateMember] = useState(false)
    const[searchbyNotificationNm,setsearchbyNotificationNm]=useState("")
    const [notificationCount, setNotificationCount] = useState<number>(0);
    const [globalSearchExpand, setGlobalSearchExpand] = useState(false);
    const[searchPaymentMember,setSearchPaymentMember]=useState<string>("")
    const [updateFileArray, setUpdateFileArray] = useState<any>([])
    const [storeAttachment, setStoreAttachment] = useState<any>([])

    return (
        <GlobalContext.Provider
            value={{
              globalSearchExpand,
              setGlobalSearchExpand,
                tabValue, 
                setTabValue,
                passwordToken, 
                setPasswordToken,
                changeActiveStaus, 
                setChangeActiveStatus,
                globaleventDetails, 
                setGlobaleventDetails,
                loggedUserDetails, 
                setLoggedUserDetails,
                passodeExpireTime, 
                setPassodeExpireTime,
                refreshNotificationList, 
                serefreshNotificationList,
                refreshTicketList, 
                setrefreshTicketList,
                filterData, 
                setFilterData,
                imgPath, 
                setImagePath,
                uploadedFilePath, 
                setUploadedFilePath,
                datafeedfileName, 
                setdatafeedFileName,
                memberRefreshList, 
                setrefreshMembertList,
                displayPayNowButton,
                setDisplayPayNowButton,
                page, 
                setPage,
                limit, 
                setLimit,
                profilrPic, 
                setProfilePic,
                imgProfilePath, 
                setProfileImagePath,
                searchbyEventNm,
                setsearchbyEventNm,
                searchbyUserNm,
                setsearchbyUserNm,
                searchbyMemberEvantNm,
                setsearchbyMemberEvantNm,
                searchbyMemberAllEvantNm,
                setsearchbyMemberAllEvantNm,
                ticketTodetails,
                setticketTodetails,
                searchbyTicketEvantNm,
                setsearchbyTicketEvantNm,
                searchmasterreport,
                setSearchmasterreport,
                searchmealreport,
                setSearchmealreport,
                displayNotificationIcon,
                setDisplayNotificationIcon,
                mobileOpen, 
                setMobileOpen,
                handleDrawerOpen,
                handleDrawerClose,
                imageLoader, 
                setImageLoader,
                loggedInUser, 
                setloggedInUser,
                afterUpdateMember, 
                setafterUpdateMember,
                searchbyNotificationNm,
                setsearchbyNotificationNm,
                notificationCount, 
                setNotificationCount,
                searchPaymentMember,
                setSearchPaymentMember,
                updateFileArray, 
                setUpdateFileArray,
                storeAttachment, 
                setStoreAttachment

            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
