import Logo from "../../../assets/ISImages/Logo.svg";

const ISLogo = () => {
  return (
    <div className="absolute top-5 xl:left-6 lg:left-6 left-6 sm:w-48 w-44">

      <img src={Logo} alt="" className="cursor-pointer" />
    </div>
  );
};

export default ISLogo;