export const USER_ROLE = {
  SUPER_ADMIN: 1,
  ADMIN_MAIN: 2,
  ADMIN: 3,
  MEMBER: 4,
  TICKET_VERIFIER: 5,
};

export const EVENT_TICKET_TYPE = {
  SINGLE:1,
  COUPLE:2
}


export const MEAL_TYPE = {
  VEG:1,
  NONVEG:2,
  SHAKE:3
}

export const FILE_TYPE = {
  PROFILE_PIC:1,
  PINLEVEL_PROOF:6,
  THUMBNAIL_IMAGE:8,
  }

export const APPROVED_STATUS = {
  PENDING:0,
  APPROVED:1,
  REJECTED:2,
  REAPPLIED:3
}