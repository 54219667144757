import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
/* import NotificationToast from "./NotificationToast";
import { GlobalContext } from "../../context/GlobalContext";
import { useContext, useEffect } from "react"; */

const REACT_APP_VAPID_KEY = process.env.REACT_APP_VAPID_KEY 

var firebaseConfig = {
  apiKey: "AIzaSyDm20HNDP6OAbir7-J3V0QSffFrmnf6dJo",
  authDomain: "infinite-success-f0660.firebaseapp.com",
  projectId: "infinite-success-f0660",
  storageBucket: "infinite-success-f0660.appspot.com",
  messagingSenderId: "441235150066",
  appId: "1:441235150066:web:5119be7aeb70a3d4b56c6b",
  measurementId: "G-KYEMCP6FRJ",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const FetchToken = async () => {
  
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: REACT_APP_VAPID_KEY /*  "BIBOseAOtv8_MaOD6Um0PD3lv1Y4bj8zDcFiJC2xaeJ6plqmcR_Ao55-4WUcxtHWtdunHoCONQMQHzt3zP8pcIs", */
    });
    if (currentToken) {
      //console.log("current token for client: ", currentToken);
      window.localStorage.setItem('firebaseToken', currentToken)
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

onMessage(messaging, (payload) => {
  // console.log(payload);

  window.localStorage.setItem("notification-badge", "true");
  window.dispatchEvent(new Event("storage"));

  toast.info(
      <span style={{ color: '#000', fontSize: '14px' }}>
        {payload.notification?.body}
      </span>
   ,
    { autoClose: 3500 }
  ) 

 // DisplayNotification()
});

/* const DisplayNotification = () => {
  console.log("NotificationToast component is mounting");

  return (

    <div>
      <NotificationToast />
    </div>
  );
} */