import {  useState, createContext } from 'react';
type SidebarContext = {
  pageCleaner:number;
  setPageCleaner: (value: number) => void,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider = (props: any) => {
  const { children } = props;
  const [pageCleaner, setPageCleaner] = useState(1);
 

  return (
    <SidebarContext.Provider
      value={{pageCleaner, setPageCleaner }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
