import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Logo from '../../components/atoms/ISLogoDashboard';
import { ListItemIcon, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import ISDashboard from '../../assets/ISImages/Leftpanel/ISDashboard.svg';
import ISDashboardAC from '../../assets/ISImages/Leftpanel/ISDashboardAC.svg';
import ISEvent from '../../assets/ISImages/Leftpanel/ISEvent.svg';
import ISEventAC from '../../assets/ISImages/Leftpanel/ISEventAC.svg';
/* import ISAddadmin from '../../assets/ISImages/Leftpanel/ISAddadmin.svg';
import ISAddadminAC from '../../assets/ISImages/Leftpanel/ISAddadminAC.svg';
 */
import ISUser from '../../assets/ISImages/Leftpanel/ISUser.svg';
import ISUserAC from '../../assets/ISImages/Leftpanel/ISUserAC.svg';
import ISLogout from '../../assets/ISImages/Leftpanel/ISLogout.svg';
import ISMyTickets from '../../assets/ISImages/member/leftPanel/ISMyTickets.svg'
import ISMyTicketsAC from '../../assets/ISImages/member/leftPanel/ISMyTicketsAC.svg'
/* import ISBilling from '../../assets/ISImages/member/leftPanel/ISBilling.svg'
import ISBillingAC from '../../assets/ISImages/member/leftPanel/ISBillingAC.svg'
import ISSetting from '../../assets/ISImages/member/leftPanel/ISSetting.svg'
import ISSettingAC from '../../assets/ISImages/member/leftPanel/ISSettingAC.svg' */
import ISReport from '../../assets/ISImages/Leftpanel/ISReport.svg';
import ISReportAC from '../../assets/ISImages/Leftpanel/ISReportAC.svg';
import { APPROVED_STATUS, USER_ROLE } from '../../config/api.config';
import { useEffect, useState ,useContext} from 'react';
import { GlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";

const LeftDashboard = () => {
  const { pathname } = useLocation();
  const [menuItems, setMenuItems] = useState<any>([]);
  const [userRole, setUserRole] = useState<any>([]);
  const {handleDrawerClose,setGlobalSearchExpand,setsearchbyNotificationNm} = useContext(GlobalContext);
  const navigate = useNavigate();
 /*  const { id } = useParams();
  const tickeNumber: any = id;
 */  let path = pathname;
  if (path === "/admin/dashboard") {
    path = "/admin/dashboard";
  } else if (path === "/admin/user/add/verifier") {
    path = "/admin/user";
  } else if (path === "/admin/user/add/member") {
    path = "/admin/user";
  } else if (path === "/admin/create/event") {
    path = "/admin/event";
  } else if (path === "/admin/add") {
    path = "/admin/addAdmin";
  } else if (path === "/admin/event/details") {
    path = "/admin/event";
  } else if (path === "/member/events") {
    path = "/member/events";
  } else if (path === "/member/event/details") {
    path = "/member/events";
  } else if (path === "/member/ticket/details") {
    path = "/member/events";
  } else if (path === "/member/check/details") {
    path = "/member/events";
  } else if (path === "/member/all-events") {
    path = "/member/events";
  } else if (path === "/member/ticket/list") {
    path = "/member/ticket/list";
  } else if (path === "/admin/reports/master") {
    path = "/admin/reports";
  } else if (path === "/admin/reports/meal") {
    path = "/admin/reports";
  }  

  const AdminmenuItems: Array<any> = [
    {
      id: "dashboard",
      title: "Dashboard",
      icon: ISDashboard,
      activeIcon: ISDashboardAC,
      path: "/admin/dashboard",
      active: true,
    },
    {
      id: "eventmagaement",
      title: "Event Management",
      icon: ISEvent,
      activeIcon: ISEventAC,
      path: "/admin/event",
      active: false,
    },
    {
      id: "usermagaement",
      title: "User Management",
      icon: ISUser,
      activeIcon: ISUserAC,
      path: "/admin/user",
      active: false,
    },
   /*  {
      id: "addadmin",
      title: "Add Admin",
      icon: ISAddadmin,
      activeIcon: ISAddadminAC,
      path: "/admin/addAdmin",
      active: false,
    }, */
    {
      id: "reports",
      title: "Reports",
      icon: ISReport,
      activeIcon: ISReportAC,
      path: "/admin/reports",
      active: false,
    },
    
  ];

  const MembermenuItems: Array<any> = [
    {
      id: "dashboard",
      title: "Dashboard",
      icon: ISDashboard,
      path: "/member/dashboard",
      activeIcon: ISDashboardAC,
      active: false  ,
    },
    {
      id: "events",
      title: "Events",
      icon: ISEvent,
      path: "/member/events",
      activeIcon: ISEventAC,
      active: true,
    },
    {
      id: 'mytickets',
      title: 'My Ticket',
      icon: ISMyTickets,
      path: '/member/ticket/list',
      activeIcon: ISMyTicketsAC,
      active: false
    },
   /*  {
      id: 'billing',
      title: 'Billing',
      icon: ISBilling,
      path: '/member/billing',
      activeIcon: ISBillingAC,
      active: false
    },
    {
      id: 'settings',
      title: 'Settings',
      icon: ISSetting,
      path: '/member/settings',
      activeIcon: ISSettingAC,
      active: false
    } */
  ];

  const VerifierItems: Array<any> = [
    {
      id: "vevents",
      title: "Events",
      icon: ISDashboard,
      path: "/verifier/dashboard",
      activeIcon: ISDashboardAC,
      active: true  ,
    },
    {
      id: "vreport",
      title: "Reports",
      icon: ISReport,
      activeIcon: ISReportAC,
      path: "/verifier/reports",
      active: false,
    }, 
   
  ];

  const handleItemClick = (item: any) => {
    updateMenuItems(item.id);
  };

  const updateMenuItems = (id: any) => {
    menuItems.forEach((item: any) => {
      if (item.id === id) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    // save the updated menu items in the local storage
  localStorage.setItem('menuItems', JSON.stringify(menuItems));
  };

  // retrieve the menu items from the local storage
const storedMenuItems = localStorage.getItem('menuItems');

if (storedMenuItems) {
  // if the menu items exist in the local storage, parse them and update the menuItems array
  const parsedMenuItems = JSON.parse(storedMenuItems);
  menuItems.forEach((item:any) => {
    const storedItem = parsedMenuItems.find((storedItem: any) => storedItem.id === item.id);
    if (storedItem) {
      item.active = storedItem.active;
    }
  });
}
 // const [userApproved,setuserApproved]=useState(0)
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("TokenData") || "{}");
    setUserRole(user?.data?.userRole);
    // setuserApproved(user?.data?.isApproved)
  }, []);

  useEffect(() => {
/*     if (userApproved == APPROVED_STATUS.APPROVED) {
 */    if (userRole?.length > 1) {
      if (userRole !== undefined) {
        userRole.map((item: any) => {
          if (item?.roleId === USER_ROLE.ADMIN) {
            setMenuItems(AdminmenuItems);
          } else if(item?.roleId === USER_ROLE.MEMBER) {
            setMenuItems(MembermenuItems);
          } else if(item?.roleId === USER_ROLE.TICKET_VERIFIER)
          {
            setMenuItems(VerifierItems);

          }
        });
      }
    } 
    else {
      if (userRole !== undefined && userRole[0]?.roleId === USER_ROLE.MEMBER) {
        setMenuItems(MembermenuItems);
      } else if (
        (userRole &&  userRole[0] !== undefined) &&
        
       ( userRole[0]?.roleId === USER_ROLE.ADMIN ||
        userRole[0]?.roleId === USER_ROLE.ADMIN_MAIN)
       
      ) {
        setMenuItems(AdminmenuItems);
      } else if (
        userRole[0]?.roleId === USER_ROLE.TICKET_VERIFIER
      ) {
        setMenuItems(VerifierItems);
      }
     /*  else{
        if(tickeNumber && userRole[0] == "")
        {
         localStorage.setItem('ticketNumber',tickeNumber )
         navigate('/login')
        }
      } */
    }
/*   }else{
    navigate("/login");
  } */
  
  }, [userRole]);

  const handleLogout = () => {
    window.localStorage.removeItem("TokenData");
    window.localStorage.removeItem("firebaseToken");
    window.localStorage.removeItem("menuItems");
    window.localStorage.removeItem("ticketNumber");
    window.localStorage.removeItem("exp");

  };

  const handleSelectItem = (text:any) =>{
    handleItemClick(text) 
    handleDrawerClose()
    setGlobalSearchExpand(false)
    setsearchbyNotificationNm("")
  }

  return (
    <div>
      <div className="overflow-y-scroll no-scrollbar min-w-[250px]">
        <div className="xl:px-6 px-4">
          <Logo />
          <List className="!mt-32 !mb-8">
            <div className="font-semibold leading-7	text-lg">Menu</div>
            {menuItems.map((text: any, index: any) => (
              <ListItem
                className="fontFamilyPoppins"
                key={index}
                disablePadding
                style={{ margin: ".7rem 0" }}
              >
                <ListItemButton
                  component={Link}
                  to={text.path}
                  className={`rounded-borderRadius10 !h-[50px] ${text.active ? 'bg-gradient-to-r from-OceanBlue to-DeepLilac' : 'bg-transparent'} ${text.active ? 'bg-gray-200' : ''} ${text.active ? 'text-white' : 'text-TextGray'} h-50 min-w-236`}  
                              onClick={() => handleSelectItem(text)}
                >
                  <ListItemIcon>
                    <img
                      alt="select_img"
                      src={text.active ? text.activeIcon : text.icon}
                      height="20px"
                      width="20px"
                      className="text-red-500	"
                    />
                  </ListItemIcon>
                  <Typography>{text.title}</Typography>
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem
              className="fontFamilyPoppins"
              disablePadding
              style={{ margin: "1rem 0" }}
              onClick={handleLogout}
            >
              <ListItemButton
                className="text-TextGray"
                component={Link}
                to={"/login"}
              >
                <ListItemIcon>
                  <img
                    alt="select_img"
                    src={ISLogout}
                    height="20px"
                    width="20px"
                  />
                </ListItemIcon>
                <Typography className="text-TextGray">{"Logout"}</Typography>
              </ListItemButton>
            </ListItem>
          </List>
          {/* <CalendarComponent /> */}
        </div>
      </div>
    </div>
  );
};
export default LeftDashboard;
