import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import LeftDrawer from "./leftDashboard";
import { GlobalContext } from "../../context/GlobalContext";

import "./index.css";
import CommonHeaderMobile from "src/components/ISCommonHeaderMobile";


interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function LeftMenu(props: Props) {
  const { window } = props;
  const {
    mobileOpen,handleDrawerOpen,handleDrawerClose
  } = React.useContext(GlobalContext);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{flexShrink: { lg: 0 } }}
        className={"responsive-app-bar xl:w-[310px] 900:w-[270px] fixed lg:bg-transparent bg-white w-[47px] h-[47px] 900:top-[12px] top-1 left-2 rounded-borderRadius10 flex justify-center items-center lg:shadow-none shadow-md z-[999]"}
        aria-label="mailbox folders"
      >

        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
        className=" xl:w-[310px] 900:w-[270px]"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
        >
          <LeftDrawer />
        </Drawer>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            className="w-auto h-full p-0 m-auto flex justify-center items-center hover:bg-transparent"
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon className="mr-0 m-auto" />
          </IconButton>
        </Toolbar>
        <Drawer
        className=" xl:w-[310px] 900:w-[270px]"
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
          open
        >
          <LeftDrawer />
        </Drawer>

      </Box>

      {/* <CommonHeaderMobile type="event" /> */}
    </Box>
  );
}
