import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { GlobalContextProvider } from "../src/context/GlobalContext";
import { SidebarProvider } from "../src/context/SidebarContext";
import { BrowserRouter } from "react-router-dom";
import App from "./app/app";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThemeProvider from "src/theme/ThemProvider";
import { CssBaseline } from "@mui/material";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider>
    <CssBaseline>
      <SidebarProvider>
        <GlobalContextProvider>
          <BrowserRouter>
            <App />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              transition={Slide}
              hideProgressBar={true}
              closeOnClick={true}
              pauseOnHover={true}
              draggable={true}
            />
          </BrowserRouter>
        </GlobalContextProvider>
      </SidebarProvider>
    </CssBaseline>
  </ThemeProvider>
);

