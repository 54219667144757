import SidebarLeft from "src/app/leftpanel";
import InnerLayout from "../inner-layout";
import { useEffect, useState } from "react";
import { APPROVED_STATUS } from "src/config/api.config";
import { useNavigate } from "react-router-dom";

const CommonComponent = () => {

  const navigate = useNavigate();

  const [userApproved,setuserApproved]=useState<number>(5)
 useEffect(() => {
  const user = JSON.parse(localStorage.getItem("TokenData") || "{}");
   setuserApproved(user?.data?.isApproved)
}, []);

  useEffect(()=>{
    if(userApproved !== 5 && userApproved !== APPROVED_STATUS.APPROVED)
    {
      navigate('/login')
    }


  },[userApproved])

  return (
    <>
      <div
        className="md:flex bg-lightGray-0">
        <div className="900:w-[270px]" style={{ flexShrink: 0 }}>
         
              <SidebarLeft />
          
        </div>
        <div
          className="flex flex-col max-w-screen-xl m-auto min-h-screen bg-lightGray-0 xl:w-[calc(100vw-310px)] 900:w-[calc(100vw-270px)] w-full" >
         
          <InnerLayout />
        </div>
      </div>
    </>
  );
};
export default CommonComponent;
