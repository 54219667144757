import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

interface InnerLayoutProps {
  children?: ReactNode;
}

const InnerLayout: FC<InnerLayoutProps> = ({ children }) => {
  return <div>{children || <Outlet />}</div>;
};

InnerLayout.propTypes = {
  children: PropTypes.node,
};

export default InnerLayout;
