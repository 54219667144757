import React, { useState } from 'react';
import { ThemeProvider } from '@mui/styles';
import { themeCreator } from './base';
import { StyledEngineProvider } from '@mui/system';


export const ThemeContext = React.createContext((themeName: string): void => {});

const ThemeProviderWrapper: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [themeName, setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);

  const handleThemeChange = (newThemeName: string): void => {
    localStorage.setItem('appTheme', newThemeName);
    setThemeName(newThemeName);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={handleThemeChange}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
