import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import BaseLayout from '../base-layout';

import SuspenseLoader from "../../components/suspense-loader";
import CommonComponent from "../inner-layout/Commoncomponent";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import("../../app/login")));
const Confirmotp = Loader(lazy(() => import("../../app/login/confirmOtp")));
const ForgetPasswordForm = Loader(
  lazy(() => import("../../app/login/forgetPasswordform")) /* forgetPasswordform => forgetPasswordForm */
);
const ForgetPasswordCode = Loader(
  lazy(() => import("../../app/login/forgetPasswordCode"))
);
const ResetPassword = Loader(
  lazy(() => import("../../app/login/resetPassword"))
);
const Dashboard = Loader(lazy(() => import("../base-layout/admindashbord"))); /* admindashboard => adminDashboard */
const AddMember = Loader(
  lazy(() => import("../../app/Admin/UserManagement/addNewMemberForm"))
);
const CommonMemberViewerSwitch = Loader(
  lazy(() => import("../../app/Admin/UserManagement/commonUserViewerSwitch"))
);
const AddTicketVerifier = Loader(
  lazy(() => import("../../app/Admin/UserManagement/addTicketVerifier"))
);
const CreateEvent = Loader(lazy(() => import("../../app/Admin/EventManagement/createEvent")));
const SwitchAccount = Loader(lazy(() => import("../../app/SwitchAccount/index")));
const EventDetails = Loader(lazy(() => import("../../app/Admin/EventManagement/eventDetails")));
const EventPage = Loader(lazy(() => import("../../app/Admin/EventManagement")));
const AdminNotification = Loader(lazy(() => import("../../app/Admin/Notification/index")));
const MemberEventList = Loader(lazy(() => import("../../app/Member/MemberEvent/memberEvent")));
const MemberDashboard = Loader(lazy(() => import("../../app/Member/MemberDashboard/index")));
const MyTicket = Loader(lazy(() => import("../../app/Member/MemberTicket/myTicket")));
const MemberEventDetails = Loader(lazy(() => import("../../app/Member/MemberEvent/memberEventDetails")));
const MemberTicketDetails = Loader(lazy(() => import("../../app/Member/MemberEvent/memberTicketDetails")));
const MemberCheckDetails = Loader(lazy(() => import("../../app/Member/MemberEvent/memberCheckDetails")));
const MemberSignUp = Loader(lazy(() => import("../../app/Member/MemberSignUp/memberSignUp")));
const VerifierDashboard = Loader(lazy(() => import("../../app/Verifier/verifierDashboard")));
const VerifierTicketDeatails = Loader(lazy(() => import("../../app/Verifier/verifierTicketDeatails")));
const UserPage = Loader(lazy(() => import("../../app/User")));
const PageNotFound = Loader(lazy(() => import("../../app/404")));
const AddAdmin = Loader(lazy(() => import("../../app/Admin/UserManagement/addAdminPage")));
const CreateAdmin = Loader(lazy(() => import("../../app/Admin/UserManagement/addAdmin")));
const MemberEventListAll = Loader(lazy(() => import("../../components/atoms/ISMemberEvents/MemberEventListAll")));
const MemberTicketList = Loader(lazy(() => import("../../app/Member/MyTicket")))
const AdminReport = Loader(lazy(()=> import("../../app/Admin/Report")))
const MasterReport = Loader(lazy(()=> import("../../app/Admin/Report/MasterReportList")))
const MealReport = Loader(lazy(()=> import("../../app/Admin/Report/MealReportList")))
const VerifierEventList = Loader(lazy(()=> import("../../app/Verifier/ListAllEvent")))
const VerifierReport = Loader(lazy(()=> import("../../app/Verifier/Report")))
const MemberProfile = Loader(lazy(()=> import("../../app/Member/MemberProfile/MemberProfile")))
const MemberReject = Loader(lazy(()=> import("../../app/Member/MemberSignUp/MemberReject")))
const PaymentReport = Loader(lazy(()=> import("../../app/Admin/Report/PaymentReportList")))

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/login" replace />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'confirmOTP',
    element: <Confirmotp />
  },
  {
    path: 'forgetPassword',
    element: <ForgetPasswordForm />
  },
  {
    path: 'resetPasswordCode',
    element: <ForgetPasswordCode />
  },
  {
    path: 'resetPassword',
    element: <ResetPassword />
  },
  {
    path: '/member/sign-up',
    element: <MemberSignUp />
  },
  {
    path: "/admin",
    element: <CommonComponent />,
    children: [
      {
        path: '/admin/dashboard',
        element: <Dashboard />
      },
      {
        path: '/admin/event',
        element: <EventPage />
      },
      {
        path: '/admin/user',
        element: <UserPage />
      },
      {
        path: '/admin/user/add/verifier',
        element: <AddTicketVerifier />
      },
      {
        path: '/admin/user/add/member',
        element: <AddMember />
      },
      {
        path: '/admin/addAdmin',
        element: <AddAdmin />
      },
      {
        path: '/admin/create/event',
        element: <CreateEvent />
      },
      {
        path: '/admin/event/details',
        element: <EventDetails />
      },
      {
        path: '/admin/add',
        element: <CreateAdmin />
      },
      {
        path: '/admin/notification',
        element: <AdminNotification />
      },
      {
        path: '/admin/reports',
        element: <AdminReport />
      },
      {
        path: '/admin/reports/master',
        element: <MasterReport />
      },
      {
        path: '/admin/reports/meal',
        element: <MealReport />
      },
      {
        path: '/admin/switch-accoount',
        element: <SwitchAccount />
      },
      {
        path: '/admin/reports/payment',
        element: <PaymentReport />
      },
      
    ]
  },
  {
    path: "/member",
    element: <CommonComponent />,
    children: [
      {
        path: '/member/dashboard',
        element: <MemberDashboard />
      },
      {
        path: '/member/events',
        element: <MemberEventList />
      },
      {
        path: '/member/event/details',
        element: <MemberEventDetails />
      },
      {
        path: '/member/ticket/details',
        element: <MemberTicketDetails />
      },
      {
        path: '/member/check/details',
        element: <MemberCheckDetails />
      },
      {
        path: '/member/ticket',
        element: <MyTicket />
      },
      {
        path: '/member/all-events',
        element: <MemberEventListAll /> 
      },
      {
        path: '/member/ticket/list',
        element: <MemberTicketList />
      },
      {
        path: '/member/profile',
        element: <MemberProfile />
      }
    ]
  },
  {
    path: "/verifier",
    element: <CommonComponent />,
    children: [
      {
        path: '/verifier/dashboard',
        element: <VerifierDashboard />
      },
      {
        path: '/verifier/ticket-details/:id',
        element: <VerifierTicketDeatails />
      },
      {
        path: '/verifier/event-all',
        element: <VerifierEventList />
      },
      {
        path: '/verifier/reports',
        element: <VerifierReport />
      }
    ]
  },
  {
    path: '/usermanagent/member',
    element: <CommonMemberViewerSwitch />
  },
  {
    path: "/memberRejected",
    element: <MemberReject />
  }
  
];

export default routes;
