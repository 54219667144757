import { useEffect } from "react";
import NProgress from "nprogress";
//import "nprogress/nprogress.css";

function SuspenseLoader() {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return <></>;
}

export default SuspenseLoader;
