import { useRoutes } from "react-router-dom";
import router from "../infrastructure/router";

import { FetchToken } from "../app/Firebase";
//import ThemeProvider from "src/theme/ThemProvider";
import { CssBaseline } from "@mui/material";
import { GlobalContext } from "src/context/GlobalContext";
import { useContext, useEffect } from "react";

const App = (): JSX.Element => {
  //Firebase integration for member signup send notification to admin
  FetchToken();

  const { setDisplayNotificationIcon } = useContext(GlobalContext);

  useEffect(() => {
    const getNotificationBadge = () => {
      setDisplayNotificationIcon(localStorage.getItem('notification-badge') === 'true' ? true : false);
    }
    getNotificationBadge();
    window.addEventListener('storage', getNotificationBadge)
  }, []);

  const content = useRoutes(router);
  return <>{content}</>;
};

export default App;
